@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&display=swap');

html,
body {
  height: 100%;
  margin:0;
  padding:0;
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #050406;
  color: #FFFFFF;
}

body {
  display:flex; 
  flex-direction:column; 
}

.container {
  position: relative;
  min-height: 100%;
  margin-top: 10%;
  width: 100%;
  z-index: 1;
}

.content-wrap {
  padding-bottom: 50px;    /* Footer height */
}

.btn {
    text-transform: uppercase;
}

.logs{
  text-transform: uppercase;
}

.btn-primary {
    background-color: #0A96F4;
    padding: 15px 35px;
    border-radius: 0;
}

.btn-menu {
    background-color: #0A96F4;
    padding: 15px 35px;
    border-radius: 0;
    margin: 0% 10%;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #050406;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  z-index: 2;
}

.navbar-link {
  margin-left: 30px;
}

.footer {
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  opacity: 0.6;
  height: 30px;
  margin-top: auto; 
}

.modal-header, .modal-footer {
  border: 0px;
}

.modal-content {
  background-color: #272727;
}

.modal-body{
  padding: 0% 10% 5%;
}
.modal-header {
  width: 100%;
  font-size: 60px;
  padding: 10% 10% 0%;
  text-transform: uppercase;
}

.modal-desc{
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  text-align: justify;
  opacity: 0.7;
}

.page-title {
  font-size: 35px;
  text-transform: uppercase;
}

.page-desc {
  font-size: 18px;
  opacity: 0.7;
  font-weight: 300;
}

.credits {
  text-align: right;
}

.credit-value{
  font-size: 24px;
}

.add-credit {
  font-size: 18px;
  opacity: 0.7;
  color: #FFFFFF;
}

.page-heading {
  margin-bottom: 20px;
}