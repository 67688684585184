@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&display=swap);

/* =============================================================================
   HTML5 CSS Reset Minified - Eric Meyer
   ========================================================================== */

   html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent}
   body{line-height:1}
   article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
   nav ul{list-style:none}
   blockquote,q{quotes:none}
   blockquote:before,blockquote:after,q:before,q:after{content:none}
   a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;text-decoration:none}
   mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
   del{text-decoration:line-through}
   abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
   table{border-collapse:collapse;border-spacing:0}
   hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}
   input,select{vertical-align:middle}
   li{list-style:none}
   
   
   /* =============================================================================
      My CSS
      ========================================================================== */
 
   
   canvas{
     display:block;
     height: 100vh;
   }
   
   /* ---- particles.js container ---- */
   
   .particles{
    right: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 1;
   }

    @media all and (max-width: 701px) {
        .particles{
            display: none;
        }

     }
html,
body {
  height: 100%;
  margin:0;
  padding:0;
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #050406;
  color: #FFFFFF;
}

body {
  display:flex; 
  flex-direction:column; 
}

.container {
  position: relative;
  min-height: 100%;
  margin-top: 10%;
  width: 100%;
  z-index: 1;
}

.content-wrap {
  padding-bottom: 50px;    /* Footer height */
}

.btn {
    text-transform: uppercase;
}

.logs{
  text-transform: uppercase;
}

.btn-primary {
    background-color: #0A96F4;
    padding: 15px 35px;
    border-radius: 0;
}

.btn-menu {
    background-color: #0A96F4;
    padding: 15px 35px;
    border-radius: 0;
    margin: 0% 10%;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #050406;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  z-index: 2;
}

.navbar-link {
  margin-left: 30px;
}

.footer {
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  opacity: 0.6;
  height: 30px;
  margin-top: auto; 
}

.modal-header, .modal-footer {
  border: 0px;
}

.modal-content {
  background-color: #272727;
}

.modal-body{
  padding: 0% 10% 5%;
}
.modal-header {
  width: 100%;
  font-size: 60px;
  padding: 10% 10% 0%;
  text-transform: uppercase;
}

.modal-desc{
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  text-align: justify;
  opacity: 0.7;
}

.page-title {
  font-size: 35px;
  text-transform: uppercase;
}

.page-desc {
  font-size: 18px;
  opacity: 0.7;
  font-weight: 300;
}

.credits {
  text-align: right;
}

.credit-value{
  font-size: 24px;
}

.add-credit {
  font-size: 18px;
  opacity: 0.7;
  color: #FFFFFF;
}

.page-heading {
  margin-bottom: 20px;
}
.hero-text {
    margin-top: 100px;
    font-weight: 300;
    font-size: 75px;
    line-height: 80px;
    margin-bottom: 20px;
}

.copy-text {
    width: 55%;
}

.copy-text p {
    font-weight: 300;
    font-size: 16px;
    line-height: 170%;
    text-align: justify;
    opacity: 0.6;
    margin-bottom: 30px;
}

@media (min-width:600px) {
    .hero-text {
        margin-top: 100px;
        font-weight: 300;
        font-size: 60px;
        line-height: 65px;
        margin-bottom: 20px;
    }

}

@media (min-width:800px) {
    .hero-text {
        margin-top: 100px;
        font-weight: 300;
        font-size: 75px;
        line-height: 80px;
        margin-bottom: 20px;
    }
}

@media (min-width:2000px) {
    .hero-text {
        margin-top: 200px;
        font-weight: 300;
        font-size: 96px;
        line-height: 112px;
        margin-bottom: 20px;
    }
}
.card {
    padding: 10px;
    background: none;
    height: 380px;
}

.card-body {
    position: relative;
    margin-bottom: 10px;
    background: rgba(10, 150, 244, 0.1);
    border: 2px solid #0A96F4;
    border-radius: 0;
}

.menu-content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.menu-text {
    text-transform: uppercase;
    padding-top: 30px;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
}

.coming-soon {
    opacity: 0.5;
}

.login-field {
    background: rgba(10, 150, 244, 0.1);
    border: 2px solid #0A96F4;
    color: white;
}

.err {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #F40A0A;
}

.login-field-failed {
    background: rgba(10, 150, 244, 0.1);
    border: 2px solid #F40A0A;
    color: white;
}
.upload-card {
    padding-top: 10px;
    padding-bottom: 10px;
    background: none;
    width: 100%;
}

.upload-status-success {
    background: #00AE1C;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 15px;
}

.upload-card-body-success {
    position: relative;
    background: #00AE1C10;
    border: 2px solid #00AE1C;
    border-radius: 0;
    height: 130px;
}

.upload-status-error {
    background: #F40A0A;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 15px;
}

.upload-card-body-error {
    position: relative;
    background: #F40A0A10;
    border: 2px solid #F40A0A;
    border-radius: 0;
    height: 130px;

}

.upload-status-uploading {
    background: #0A96F4;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 15px;
}

.upload-card-body {
    position: relative;
    background: rgba(10, 150, 244, 0.1);
    border: 2px solid #0A96F4;
    border-radius: 0;
    height: 130px;
}

.upload-content {
    padding: 20px;
}

.upload-text {
    font-weight: 300;
    font-size: 18px;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.upload-reminder {
    padding-top: 10px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
}
.progress-row {
    margin-bottom: 10px;
}

.progress {
    border: 1px solid #0A96F4;
    background: none;
    padding: 5px;
    height: 20px;
    margin-bottom: 2px;
}

.progress-bar {
    background: #0A96F4;
    padding: 2px;
    width: 0%;
}

.progress-percent-div {
    text-align: right;
}

.progress-percent {
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
    color: #0A96F4;
}

.buildai-card-title {
    font-size: 18px;
    text-transform: uppercase;
}

.buildai-card {
    background: none;
    height: 300px;
}

.buildai-card-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-weight: 300;
}

.buildai-card-body {
    font-weight: 300;
    margin-bottom: 10px;
    background: rgba(10, 150, 244, 0.1);
    border: 2px solid #0A96F4;
    border-radius: 0;
    height: 270px;
    padding: 10px;
}
.results-sm-card {
    padding: 10px;
    background: rgba(10, 150, 244, 0.1);
    border: 2px solid #0A96F4;
    border-radius: 0;
    height: 110px;
}

.top-results-row {
    margin-bottom: 15px;
}

.right-results-col {
    padding-left: 0px;
}

.result-num {
    padding-top: 15px;
    font-weight: normal;
    font-size: 36px;
}

.result-type {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
}

.btn-results-menu {
    width: 100%;
    margin: 7px 0px;
    text-transform: none;
    text-transform: initial;
}

.results-other-info {
    margin-top: 15px;
}

.results-textarea {
    margin-top: 10px;
    background: rgba(10, 150, 244, 0.1);
    border: 2px solid #0A96F4;
    color: #FFFFFF;
}

.chart-img {
    width: 100%;
}

.chart-desc {
    margin-top: 10px;
    font-weight: 300;
    opacity: 0.8;
}
