/* =============================================================================
   HTML5 CSS Reset Minified - Eric Meyer
   ========================================================================== */

   html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent}
   body{line-height:1}
   article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
   nav ul{list-style:none}
   blockquote,q{quotes:none}
   blockquote:before,blockquote:after,q:before,q:after{content:none}
   a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;text-decoration:none}
   mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
   del{text-decoration:line-through}
   abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
   table{border-collapse:collapse;border-spacing:0}
   hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}
   input,select{vertical-align:middle}
   li{list-style:none}
   
   
   /* =============================================================================
      My CSS
      ========================================================================== */
 
   
   canvas{
     display:block;
     height: 100vh;
   }
   
   /* ---- particles.js container ---- */
   
   .particles{
    right: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 1;
   }

    @media all and (max-width: 701px) {
        .particles{
            display: none;
        }

     }