.results-sm-card {
    padding: 10px;
    background: rgba(10, 150, 244, 0.1);
    border: 2px solid #0A96F4;
    border-radius: 0;
    height: 110px;
}

.top-results-row {
    margin-bottom: 15px;
}

.right-results-col {
    padding-left: 0px;
}

.result-num {
    padding-top: 15px;
    font-weight: normal;
    font-size: 36px;
}

.result-type {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
}

.btn-results-menu {
    width: 100%;
    margin: 7px 0px;
    text-transform: initial;
}

.results-other-info {
    margin-top: 15px;
}

.results-textarea {
    margin-top: 10px;
    background: rgba(10, 150, 244, 0.1);
    border: 2px solid #0A96F4;
    color: #FFFFFF;
}

.chart-img {
    width: 100%;
}

.chart-desc {
    margin-top: 10px;
    font-weight: 300;
    opacity: 0.8;
}